import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'amfe-flexible'
import Vant from 'vant'
import 'vant/lib/index.css'
import axios from 'axios'

Vue.use(Vant)

/**
 * axios设置
 */
const $axios = axios.create({
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    'Access-Control-Allow-Origin': '*'
  }
})
const requestUrl = 'https://gwtpadmin.data0551.cn'
$axios.interceptors.request.use(config => {
  if (process.env.NODE_ENV === 'production') {
    config.url = requestUrl + config.url
  }
  return config
})

$axios.interceptors.response.use(response => {
  if (store && response && response.data) {
    // 刷新服务器时间
    // store.commit('setTimestamp', response.data.timestamp)
  }
  return response
}, error => {
  Vue.nextTick(() => {
    if (error.response && error.response.config) {
      switch (error.response.status) {
        case 401: {
          break
        }
        case 403: {
          break
        }
        case 404: {
          break
        }
        case 500: {
          break
        }
        default: {
          break
        }
      }
    }
  })
  return Promise.reject(error)
})

Vue.config.productionTip = false
/* 引入ajax请求方案 */
Vue.prototype.$ajax = function (config) {
  config.instance = this
  return $axios(config)
}
Vue.prototype.$extend = function () {
  return Object.assign({}, ...arguments)
}

Vue.prototype.$requestUrl = requestUrl
Vue.prototype.$ObjectToArray = function (obj) {
  const arr = []
  Object.keys(obj).forEach(key => {
    arr.push(obj[key])
  })
  return arr
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
