import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routes = [
  {
    path: '',
    component: () => import('@/views/index'),
    redirect: '/home',
    children: [{
      path: 'home',
      component: () => import('@/views/0705/index')
    }, {
      path: 'cgsj',
      component: () => import('@/views/0707/index')
    }, {
      path: 'cptzq',
      component: () => import('@/views/07061/index')
    }, {
      path: 'cwtzq',
      component: () => import('@/views/07062/index')
    }, {
      path: 'cttzq',
      component: () => import('@/views/07063/index')
    }]
  }
]

const router = new Router({
  routes: routes
})

export default router
