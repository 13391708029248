export default {
  state: {
    lang: window.localStorage.getItem('lang') || 'zh',
    bgColor: '#fff'
  },
  mutations: {
    setLang: function (state, lang) {
      state.lang = lang
      if (window.localStorage) {
        window.localStorage.setItem('lang', lang)
      }
    },
    setBgColor: function (state, bgColor) {
      state.bgColor = bgColor
    }
  }
}
